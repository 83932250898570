import { defineStore } from "pinia";
import { ProPriceCategory, RelatedPros, User } from "@/types/user";
import { handleError } from "@/utils/error";
import ProService from "@/services/pro-service";
import { PaginatedResult } from "@/types/pagination";
import config from "../config";
import { ComputedRef } from "nuxt/dist/app/compat/capi";

const proService = new ProService("pros");

export const useProStore = defineStore("pro", {
  state: () => ({
    pros: [] as User[],
    total: 0,
    pro: {} as User,
    related: {
      job: "",
      related: [] as User[],
    } as RelatedPros,
  }),
  actions: {
    async getFeaturedPros(): Promise<{
      featured: User[];
      ars: User[];
      managers: User[];
    } | void> {
      try {
        return await proService.getFeaturedPros();
      } catch (error) {
        handleError(error);
        return { featured: [], ars: [], managers: [] };
      }
    },

    async getRelatedPros(uniqueName: string) {
      try {
        const related = await proService.getRelatedPros(uniqueName);

        if (related) {
          this.related = related;
        }
      } catch (error) {
        throw handleError(error);
      }
    },

    async getProUniqueNames(): Promise<string[] | void> {
      try {
        return await proService.getProUniqueNames();
      } catch (error) {
        handleError(error);
        return [];
      }
    },

    async fetchPros(
      query: ComputedRef<Object>,
    ): Promise<{ pending: globalThis.Ref<boolean> }> {
      try {
        let authToken;

        if (!process.server) {
          authToken = localStorage.getItem("authToken");
        }

        const { pending } = await useFetch<{
          data: User[];
          total: number;
        }>(`${config("apiBaseUrl")}/pros`, {
          params: query,
          key: `pros/${JSON.stringify(query.value)}`,
          watch: [() => query],
          onResponse: ({ response }) => {
            this.pros = response._data.data || [];
            this.total = response._data?.total || 0;
          },
          headers: {
            ...(authToken && { Authorization: `Bearer ${authToken}` }),
          },
        });

        return { pending };
      } catch (error) {
        throw handleError(error);
      }
    },

    async getAll(uri: string): Promise<PaginatedResult<User> | void> {
      try {
        const res: PaginatedResult<User> | void = await proService.list(uri);
        if (res && res.data) {
          this.pros = res.data;
          if (
            uri.includes("column=priceStartingFrom") &&
            uri.includes("order=1")
          ) {
            let maxLoops = this.pros.length;
            while (
              this.pros[0].pricing === ProPriceCategory.free &&
              --maxLoops > 0
            ) {
              this.pros.push(this.pros.shift()!);
            }
          }
          this.total = res.total;
          return res;
        }
      } catch (error) {
        throw handleError(error);
      }
    },

    async getByUniqueName(uniqueName: string): Promise<void> {
      try {
        // this.pro = await proService.getByUniqueName(uniqueName);
        const pro = await proService.getByUniqueName(uniqueName);
        if (pro) {
          this.pro = pro;
        }
      } catch (error) {
        throw error;
      }
    },

    async getProById(id: string): Promise<User | void> {
      try {
        return await proService.getById(id);
      } catch (error) {
        throw handleError(error);
      }
    },
  },
});
